import React, {FunctionComponent, ReactNode, useContext} from 'react';
import {Helmet} from 'react-helmet';
import {StaticQuery, graphql} from 'gatsby';

export type Props = {
  children: ReactNode;
  location?: {pathname: string};
};

const Layout: FunctionComponent<Props> = ({children, location}) => {
  let content =
    location && location.pathname === '/' ? (
      <div>{children}</div>
    ) : (
      <div id="wrapper" className="page">
        <div>{children}</div>
      </div>
    );

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {name: 'description', content: 'Sample'},
              {name: 'keywords', content: 'sample, something'}
            ]}
          >
            <html lang="en" />
            <script src="https://www.google.com/recaptcha/api.js" async defer />
          </Helmet>
          {content}
        </>
      )}
    />
  );
};

export default Layout;
